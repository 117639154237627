.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2, h4, p {
  margin-bottom: 20px;
}

p {
  line-height: 1.6;
  text-justify: auto;
}

h4 {
  color: #333;
  text-align: center;
}

.card {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
  max-width: 250px;
  display: inline-block;
}

.card .card-image{
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

.card .card-title{
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  color: rgb(7, 7, 7);
}

.card .card-text{
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(11, 10, 10);
}