.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background: linear-gradient(to right, #8329E1, #7426DF, #705AD4, #5477CB, #13D1B0);
}

.logo img {
  max-height: 65px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.btn-login, .btn-signup {
  padding: 0.5em 1em;
  border-radius: 40px;
  border: 2px solid white;
  background: none;
  cursor: pointer;
  transition: 0.3s;
}

.btn-login {
  color: white;
  font-size: medium;
}

.btn-signup {
  background-color: white;
  color: turquoise;
  font-size: medium;
}

.btn-login:hover, .btn-signup:hover {
  opacity: 0.8;
}

.btn-login:hover {
  color: #7426DF;
  background-color:white;
}

.btn-signup:hover {
  color: white;
  background-color: #7426DF;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}